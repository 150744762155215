import axios from 'axios';
import type { AxiosPromise, AxiosResponse } from 'axios';
import type { OrganisationEdit } from '@/models/organisation.model';

export function fetchOrganizations(): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/organisations/`, {
      headers: { uid: 1 },
    })
    .then((response: AxiosResponse) => response);
}

export function createOrganisation(data: OrganisationEdit): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/organisations/`, data)
    .then((response: AxiosResponse) => response);
}

export function fetchSingleOrganisation(id: number): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/organisations/${id}/`)
    .then((response: AxiosResponse) => response);
}

export function editOrganisation(id: number, data: OrganisationEdit): Promise<AxiosResponse> {
  return axios
    .patch<AxiosPromise>(`/api/organisations/${id}/`, data)
    .then((response: AxiosResponse) => response);
}

export function deleteOrganisation(id: number): Promise<AxiosResponse> {
  return axios.delete<AxiosPromise>(`/api/organisations/${id}/`);
}

export function fetchFeatures(): Promise<AxiosResponse> {
  return axios.get<AxiosPromise>(`/api/features/`).then((response: AxiosResponse) => response);
}
