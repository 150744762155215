<template>
  <div>
    <div v-if="loading" class="loader-wrapper">
      <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
    </div>
    <Message
      v-if="notAuthorized"
      data-cy="organisations-unauthorized-message"
      severity="warn"
      icon="pi pi-exclamation-triangle"
      :closable="false"
      >{{ $t('notAuthorized') }}</Message
    >
    <div
      v-if="!loading && !notAuthorized && childTableName === USERS"
      class="flex justify-between w-full gap-6 mb-8"
    >
      <HeaderCard data-cy="header-card-left" :title="$t('organisation.my')" icon="pi-building">
        <template #left>
          <span v-if="userOrganisationData" class="font-medium">{{
            userOrganisationData.name
          }}</span>
          <span v-else class="empty">--</span>
          <span class="font-medium"
            >{{ userOrganisationData?.users?.length ? userOrganisationData.users.length : 0 }}
            {{ $t('members') }}</span
          >
          <span class="font-medium"
            >{{ childOrganisationCount ? childOrganisationCount : 0 }}
            {{ $t('suborganizations') }}</span
          >
        </template>
        <template #right>
          <span class="pb-2 block text-sm font-medium">{{ $t('organisation.features') }}</span>
          <Tag
            v-for="(item, index) in me.active_features"
            :key="index"
            data-cy="organisation-feature"
            class="mr-2 mb-2 feature-tag"
            severity="success"
            :value="item.name"
            rounded
          ></Tag>
        </template>
      </HeaderCard>
      <HeaderCard data-cy="header-card-right" :title="$t('user.myAccount')" icon="pi-user">
        <template #left>
          <div data-cy="header-card-user-name">
            <span class="font-medium pr-2">{{ $t('name') }}</span>
            <span class="font-medium">{{ me.name }}</span>
          </div>
          <div data-cy="header-card-user-email">
            <span class="font-medium pr-2">{{ $t('email') }}</span>
            <span class="font-medium">{{ me.email }}</span>
          </div>
        </template>
        <template #right>
          <span class="pb-2 block text-sm font-medium">{{ $t('user.role.myRoles') }}</span>
          <Tag
            v-for="(item, index) in me.roles.slice(0, 5)"
            :key="index"
            data-cy="organisation-role"
            class="mr-2 mb-2 feature-tag"
            severity="success"
            :value="item.name"
            rounded
          ></Tag>
          <div v-if="me.roles.length > 5" class="inline-block align-middle">
            <span>+{{ me.roles.length - 5 }} more</span>
            <i
              class="pi pi-eye text-neutral-500 border border-l border-gray-300 rounded p-0.5 inline-block ml-2"
              v-tooltip.bottom="{
                value: `
                <div>${me.roles
                  .slice(5)
                  .map((role) => `<span class='custom-tag'>${role.name}</span>`)
                  .join('')}</div>`,
                escape: true,
                class: 'custom-tooltip',
              }"
            ></i>
          </div>
        </template>
      </HeaderCard>
    </div>
    <DataTable
      v-if="!loading && !notAuthorized"
      class="p-datatable-sm organisations-table"
      data-cy="organisations-table"
      :value="organisations"
      v-model:expanded-rows="expandedRows"
    >
      <template #empty>
        <div data-cy="organisations-empty" class="organisations-error mt-2 mb-4 mx-auto">
          <p class="text-center">{{ emptyTableMessage }}</p>
        </div>
      </template>
      <template #loading>
        <p class="text-center">{{ $t('organisation.loading') }}</p></template
      >
      <template #header>
        <div
          v-if="childTableName === USERS && suborganisationFeature && activeOrganisationRoles"
          class="flex justify-end items-start"
        >
          <Button
            data-cy="open-organisation-modal"
            :label="$t('create', { itemName: $t('organisation.title') })"
            icon="pi pi-plus"
            class="p-button-sm p-button-primary align-self-end"
            @click="openOrganisationModal"
          ></Button>
        </div>
      </template>
      <Column
        data-cy="organisation-expander"
        :expander="true"
        bodyStyle="width: 3rem"
        class="no-border-row"
      >
      </Column>
      <Column class="no-border-row" bodyStyle="width: '80%'">
        <template #body="{ data }">
          <div class="flex flex-col justify-start">
            <span :id="data.id" data-cy="organisation-name" class="text-lg font-semibold">
              {{ data.name }}
            </span>
            <span v-if="data.users" class="text-xs text-gray-400">
              {{ data.users.length }}
              {{ data.users.length === 1 ? $t('user.title') : $t('users') }}
            </span>
            <div class="text-xs text-gray-400">
              <span v-if="data.parent_organisation_id === null" data-cy="master">
                {{ $t('organisation.master') }}
              </span>
              <span v-else class="pl-1">
                {{ $t('organisation.parent') }}:
                <span data-cy="parent-name">{{ data.parent_organisation_name }}</span>
              </span>
            </div>
          </div>
        </template>
      </Column>
      <Column :class="`no-border-row flex gap-2 w-full max-w-max ml-auto`">
        <template #body="{ data }">
          <div v-if="childTableName === USERS">
            <Button
              v-if="activeOrganisationRoles"
              :data-cy="`open-edit-organisation-modal`"
              :label="$t('edit', { itemName: $t('organisation.title') })"
              icon="pi pi-pencil"
              class="mr-2 p-button-outlined p-button-plain p-button-sm"
              @click="editCurrentOrganisation(data)"
            />
            <Button
              v-if="activeOrganisationRoles"
              :label="t('organisation.delete')"
              icon="pi pi-trash"
              class="mr-2 p-button-sm p-button-danger"
              @click="openDeleteOrganisationModal(data)"
            />
            <Button
              v-if="activeUserRoles"
              data-cy="open-create-user-modal"
              :label="$t('create', { itemName: $t('user.title') })"
              icon="pi pi-plus"
              class="p-button-sm p-button-primary"
              @click="createUser(data)"
            />
          </div>
          <div v-else>
            <Button
              :data-cy="`open-charge-park-modal-${data.name}`"
              :label="$t('create', { itemName: $t('chargePark.title') })"
              icon="pi pi-plus"
              class="p-button-sm p-button-primary"
              @click="createChargePark(data)"
            />
          </div>
        </template>
      </Column>
      <template #expansion="{ data }">
        <component :is="customTable" :data="data" />
      </template>
    </DataTable>
    <OrganisationModal />
    <OrganisationDeleteModal />
    <UserModal />
    <UserDeleteModal />
    <ChargeParkModal />
    <ChargeParkDeleteModal />
  </div>
</template>

<script setup lang="ts">
import { onMounted, shallowRef, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useOrganisationsStore } from '@/stores/admin/organizations/organisations.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { Feature, Role, USERS, CHARGE_PARKS } from '@/utils/constants';
import { useI18n } from 'vue-i18n';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Message from 'primevue/message';
import Tag from 'primevue/tag';
import HeaderCard from '@/components/common/layout/HeaderCard.vue';
import OrganisationModal from '@/components/organisations/OrganisationModal.vue';
import OrganisationDeleteModal from '@/components/organisations/OrganisationDeleteModal.vue';
import UserModal from '@/components/users/UserModal.vue';
import UserDeleteModal from '@/components/users/UserDeleteModal.vue';
import ChargeParkModal from '@/components/chargeParks/ChargeParkModal.vue';
import ChargeParkDeleteModal from '@/components/chargeParks/ChargeParkDeleteModal.vue';

import type { Organisation, OrganisationWithUsers } from '@/models/organisation.model';
import type { Component } from 'vue';

const props = defineProps<{
  childTable: Component;
  childTableName: String;
}>();

const { t } = useI18n();
const organisationsStore = useOrganisationsStore();
const usersStore = useUsersStore();
const chargeParksStore = useChargeParksStore();
const {
  organisations,
  organisationModalIsOpen,
  organisationIsEditing,
  userOrganisationData,
  organisationDeleteModalIsOpen,
  organisationDeleteData,
} = storeToRefs(organisationsStore);
const { userModalIsOpen, userOrganisation, me } = storeToRefs(usersStore);
const { chargeParkModalIsOpen, chargeParkOrganisation } = storeToRefs(chargeParksStore);
const expandedRows = ref<Organisation[]>([]);
const notAuthorized = ref(false);
const loading = ref(true);
const childOrganisationCount = ref();
const customTable = shallowRef(props.childTable);

const emptyTableMessage = computed(() =>
  props.childTableName === CHARGE_PARKS && organisations.value.length === 0
    ? t('chargePark.noSitesFound')
    : t('organisation.empty')
);

const suborganisationFeature = computed(() =>
  me.value.active_features.map((feature) => feature.id).includes(Feature.SUBORGANISATIONS)
);
const activeUserRoles = computed(() => {
  return (
    me.value.roles.map((role) => role.id).includes(Role.ADMINISTRATOR) ||
    me.value.roles.map((role) => role.id).includes(Role.USER_ADMIN)
  );
});

const activeOrganisationRoles = computed(() => {
  return (
    me.value.roles.map((role) => role.id).includes(Role.ADMINISTRATOR) ||
    me.value.roles.map((role) => role.id).includes(Role.ORGANISATION_ADMIN)
  );
});

const openOrganisationModal = () => {
  organisationModalIsOpen.value = true;
};

const editCurrentOrganisation = (data: Organisation) => {
  organisationsStore.setOrganisationEditionData(data);
  organisationIsEditing.value = true;
  organisationModalIsOpen.value = true;
};

const openDeleteOrganisationModal = (data: OrganisationWithUsers) => {
  organisationDeleteData.value.id = data.id;
  organisationDeleteData.value.name = data.name;
  organisationDeleteData.value.parent_organisation_name = data.parent_organisation_name;
  organisationDeleteData.value.number_of_users = data.users?.length;
  organisationDeleteModalIsOpen.value = true;
};

const createUser = (organisation: any) => {
  userOrganisation.value.name = organisation.name;
  userOrganisation.value.id = organisation.id;
  userOrganisation.value.roles = organisation.available_roles;
  userModalIsOpen.value = true;
};

const createChargePark = (organisation: any) => {
  chargeParkOrganisation.value.name = organisation.name;
  chargeParkOrganisation.value.id = organisation.id;
  chargeParkModalIsOpen.value = true;
};

const fetchUsers = () => {
  return organisationsStore
    .fetchUsersForOrganisations(organisations.value)
    .then(() => {
      expandedRows.value.push(organisations.value[0]);
    })
    .catch((error) => {
      throw new Error(error);
    });
};

const fetchChargeParks = () => {
  organisationsStore
    .fetchChargeParksForOrganisations(organisations.value)
    .then(() => {
      expandedRows.value.push(organisations.value[0]);
    })
    .catch((error) => {
      throw new Error(error);
    });
};

onMounted(async () => {
  await organisationsStore
    .fetchOrganisationsList()
    .then(() => {
      if (props.childTableName === USERS) {
        fetchUsers().then(() => {
          userOrganisationData.value = organisations.value.find(
            (org) => org.id === me.value.organisation_id
          );
          childOrganisationCount.value = organisations.value.filter(
            (org) => org.parent_organisation_id === me.value.organisation_id
          ).length;
        });
      } else {
        fetchChargeParks();
      }
    })
    .catch((error) => {
      if (error.response.status === 403) {
        notAuthorized.value = true;
      } else {
        throw new Error('Organisations failed to be fetched');
      }
    })
    .finally(() => (loading.value = false));
});
</script>

<style lang="scss" scoped>
.organisations-table {
  max-width: 100%;
}
.feature-tag {
  padding: 2px 8px;
  font-size: 14px;
}
:deep(.p-tag.p-tag-info) {
  background-color: $grey-medium-light;
  color: $text-medium;
  line-height: 16px;
}

.more {
  width: 100px;
}

.hierarchy {
  max-width: 470px;
}
</style>
