<template>
  <div
    v-if="modelValue?.count && modelValue?.count > 0"
    v-tooltip.left="{
      value: connectorTooltip,
      pt: {
        root: 'min-w-max',
      },
    }"
    class="w-8 h-12 rounded-md"
    :style="`background-color: ${connectorStateColor}`"
  >
    <span class="flex items-center justify-center h-full"> x{{ modelValue?.count }} </span>
  </div>
</template>

<script setup lang="ts">
import { defineModel } from 'vue';
import type { FusedConnectorStates } from '@/models/hardwareSystems.model';
import { computed } from 'vue';
import { BysColor } from '@/utils/colors';

const modelValue = defineModel<FusedConnectorStates>();

const connectorTooltip = computed(() => {
  const result = modelValue.value?.connector_ids
    .map((item) => {
      return `${item.system_id} Connector ${item.connector_id + 1}: ${modelValue.value?.name}`;
    })
    .join('\n ');
  return result;
});

const connectorStateColor = computed(() => {
  if (!modelValue.value?.color) return new BysColor('black').hexString();
  return new BysColor(modelValue.value?.color).hexString();
});
</script>
