import type { Dashboard } from '@/models/controlPanel/dashboard';
import type {
  GenericSetting,
  GenericSettingEnum,
  GenericSettingNumber,
} from '@/models/datatypes.model';
import type { BrandingTemplate } from '@/stores/admin/branding/branding.types';

export interface ProductTypeProperties {
  schemaVersion: 1;
  commands: {};
  dashboard: Dashboard;
  channels: {
    [channel: string]: ProductTypeChannel;
  };
  telemetryDeviceIds: {};
  settings: ProductTypeSetting[];
  branding_template?: BrandingTemplate;
}

export interface ProductTypeCommonSetting {
  path: string;
  description?: string;
  accessLevelRead?: number; // TODO: deprecated
  accessLevelWrite?: number; // TODO: deprecated
  writable?: boolean;
}

export interface ProductTypeBooleanSetting extends ProductTypeCommonSetting {
  type: 'bool';
  defaultValue: boolean;
}

export interface ProductTypeNumberSetting extends ProductTypeCommonSetting {
  type: 'int8' | 'uint8' | 'int16' | 'uint16' | 'int32' | 'uint32' | 'float' | 'double';
  defaultValue: number;
  minValue: number;
  maxValue: number;
  unit?: string;
}

export interface ProductTypeStringSetting extends ProductTypeCommonSetting {
  type: 'string';
  defaultValue: string;
}

export interface ProductTypeEnumSetting extends ProductTypeCommonSetting {
  type: 'enum';
  enumValues: { value: number; label: string; selectable: boolean }[];
  defaultValue: number;
}

export type ProductTypeSetting =
  | ProductTypeBooleanSetting
  | ProductTypeNumberSetting
  | ProductTypeStringSetting
  | ProductTypeEnumSetting;

interface ProductTypeCommonChannel {
  name: string;
  description: string;
  accessLevelRead: number;
  accessLevelWrite: number;
}

export interface ProductTypeBooleanChannel extends ProductTypeCommonChannel {
  datatype: 'bool';
}

export interface ProductTypeStringChannel extends ProductTypeCommonChannel {
  datatype: 'string';
}

export interface ProductTypeNumberChannel extends ProductTypeCommonChannel {
  datatype: 'int8' | 'uint8' | 'int16' | 'uint16' | 'int32' | 'uint32' | 'float' | 'double';
  unit?: string;
}

export interface ProductTypeEnumChannel extends ProductTypeCommonChannel {
  datatype: 'enum8';
  enumValues: { value: number; label: string }[];
}

export type ProductTypeChannel =
  | ProductTypeBooleanChannel
  | ProductTypeStringChannel
  | ProductTypeNumberChannel
  | ProductTypeEnumChannel;

export function settingIsBoolean(
  setting?: ProductTypeSetting
): setting is ProductTypeBooleanSetting {
  return setting?.type === 'bool';
}

const NUMBER_TYPES: GenericSetting['type'][] = [
  'int8',
  'uint8',
  'int16',
  'uint16',
  'int32',
  'uint32',
  'float',
  'double',
];

export function isNumericalType(type: GenericSetting['type']): boolean {
  return NUMBER_TYPES.includes(type);
}

export function settingIsNumber(setting?: ProductTypeSetting): setting is ProductTypeNumberSetting {
  return setting?.type ? NUMBER_TYPES.includes(setting.type) : false;
}

export function settingIsNumberGeneric(setting?: GenericSetting): setting is GenericSettingNumber {
  return setting?.type ? NUMBER_TYPES.includes(setting.type) : false;
}

export function settingIsEnumGeneric(setting?: GenericSetting): setting is GenericSettingEnum {
  return setting?.type === 'enum';
}

export function settingIsString(setting: ProductTypeSetting): setting is ProductTypeStringSetting {
  return setting?.type === 'string';
}

export function settingIsEnum(setting?: ProductTypeSetting): setting is ProductTypeEnumSetting {
  return setting?.type === 'enum';
}

export interface ProductType {
  id: number;
  name: string;
  image_url: string;
  default_dashboard_id: Dashboard['id'];
  properties: ProductTypeProperties;
}

export interface ProductTypeList {
  id: number;
  name: string;
}
