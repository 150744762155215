<script setup lang="ts">
import { onMounted, ref } from 'vue';
// Custom components
import DropdownsNavbarButton from '@/components/hardwareSystems/general/navigation/DropdownsNavbarButton.vue';
// Pinia stores
import { useControlPanelStore } from '@/stores/admin/controlPanel/controlPanel.store';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
// API and Types
import type { HardwareSystem, HardwareSubSystem } from '@/models/hardwareSystems.model';
import { fetchProductType } from '@/stores/admin/controlPanel/controlPanel.api';

const props = defineProps<{
  activeTab: string;
}>();

// initalizing stores
const controlPanelStore = useControlPanelStore();
const systemsStore = useHardwareSystemsStore();

// initalizing parent dropdown data
const parentSystemDropdownLabel: string | undefined =
  controlPanelStore.productType?.properties.dashboard.dropdown_label;
const parentSystemID: number | null = controlPanelStore.systemId;
const parentSystemSerialNumber: string | undefined = controlPanelStore.hardwareSystem?.nidec_id;

// initializing loading state
const loading = ref(false);

// Track the currently selected dropdown and its ID
const selectedDropdown = ref<{ label: string | undefined; id: HardwareSystem['id'] | null } | null>(
  {
    label: parentSystemDropdownLabel,
    id: parentSystemID,
  }
);

export type DropdownDataType = {
  dropdown_label: string;
  dropdown_items: DropdownItemType[];
};

export type DropdownItemType = {
  serial_number: string | undefined;
  id: number | null;
};

// declaring the dropdowns array ref variable
const dropdowns = ref<DropdownDataType[] | undefined>([{ dropdown_label: '', dropdown_items: [] }]);
// initializing the systemChildren array
const systemChildren = ref<HardwareSubSystem[] | undefined>(
  controlPanelStore.hardwareSystem?.combined_system_setup.children
);
//initializing system chidren product types aggregate array
const systemChildrenProductTypes = ref<string[]>();

const getSystemProductType = async (id: number) => {
  const childSystem = await systemsStore.fetchSingleSystem(id).then((response) => response.data);
  const productType = await fetchProductType(childSystem.product_type_name);

  return productType;
};

// Aggregate array of all children product types
const getAggregatedProductTypes = (children: any[]) => {
  if (!parentSystemDropdownLabel) return;
  let labels: string[] = [parentSystemDropdownLabel];
  children.forEach(async (child) => {
    const productType = await getSystemProductType(child.id);
    const productProperties = productType.properties;
    const dropdownLabel = productProperties.dashboard.dropdown_label;

    if (!labels.includes(dropdownLabel)) {
      labels.push(dropdownLabel);
    }
  });
  return labels;
};

// Dropdown items fused by dropdown label and child data (serial number and id)
const getDropdownItemFusedData = async (child: any) => {
  const productType = await getSystemProductType(child.id);
  const productProperties = productType.properties;
  const dropdownLabel = productProperties.dashboard.dropdown_label;
  const panels = productProperties.dashboard.content.panels;
  let itemData: DropdownItemType | undefined;

  if (panels) {
    panels.forEach((panel: any) => {
      if (panel.id === props.activeTab) {
        itemData = { serial_number: child.nidec_id, id: child.id };
      }
    });
  }

  const result = { dropdown_label: dropdownLabel, dropdown_items: itemData };

  return result;
};

// Fused dropdown data by product types dropdown label
const fuseDropdownData = async () => {
  loading.value = true;
  let dropdownItemFusedData: any[] = [];

  // Set children product types aggregated array and the dropdown item fused data if the system has chidren
  if (systemChildren.value && systemChildren.value.length > 0) {
    systemChildrenProductTypes.value = await getAggregatedProductTypes(systemChildren.value);

    dropdownItemFusedData = await Promise.all(systemChildren.value.map(getDropdownItemFusedData));

    //Add parent data to the dropdowns fused data
    dropdownItemFusedData.push({
      dropdown_label: parentSystemDropdownLabel,
      dropdown_items: { serial_number: parentSystemSerialNumber, id: parentSystemID },
    });
  }

  // Update the dropdowns ref variable with the final dropdowns by label
  dropdowns.value = systemChildrenProductTypes.value?.map((dropdownLabel) => {
    let dropdownItems: DropdownItemType[] = [];
    dropdownItemFusedData.forEach((item) => {
      if (item.dropdown_label === dropdownLabel && item.dropdown_items) {
        dropdownItems.push(item.dropdown_items);
      }
    });

    return {
      dropdown_label: dropdownLabel,
      dropdown_items: dropdownItems,
    };
  });

  loading.value = false;
};

onMounted(async () => {
  await fuseDropdownData();
});

// emits for the selected dropdown item
const emits = defineEmits<{
  (e: 'update:selectedSystem', id: number | null): void;
  (e: 'refetchSystem'): void;
}>();

const selectedSystem = (label: string, id: number | null) => {
  if (id !== null) {
    selectedDropdown.value = { label, id };
    emits('update:selectedSystem', id);
  } else {
    selectedDropdown.value = null;
    emits('update:selectedSystem', controlPanelStore.systemId);
  }
};
</script>

<template>
  <div
    v-if="systemChildren && systemChildren.length > 0"
    class="flex p-4 gap-[2px] bg-white border-bottom border-neutral-200"
  >
    <DropdownsNavbarButton
      v-for="(dropdown, index) in dropdowns"
      :key="index"
      :values="dropdown.dropdown_items"
      :label="dropdown.dropdown_label"
      :loading="loading"
      :system="controlPanelStore.hardwareSystem"
      :selectedId="selectedDropdown?.label === dropdown.dropdown_label ? selectedDropdown.id : null"
      @update:selectedSystem="(label, id) => selectedSystem(label, id)"
    />
  </div>
</template>

<style scoped>
:deep(.p-inputwrapper-filled) {
  background-color: var(--green-primary);
}

:deep(.p-inputwrapper-filled) .p-dropdown-trigger svg.input-icon path,
:deep(.p-inputwrapper-filled) svg.input-icon path {
  stroke: white;
}
</style>
