<script setup lang="ts">
import { computed } from 'vue';
// PrimeVue components
import Dropdown from 'primevue/dropdown';
// API and Types
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import type { DropdownItemType } from '@/components/hardwareSystems/general/navigation/DropdownsNavbar.vue';

const props = defineProps<{
  values: DropdownItemType[];
  label: string;
  loading: boolean;
  system: HardwareSystem;
  selectedId: number | null | undefined;
}>();

const emits = defineEmits<{
  (e: 'update:selectedSystem', label: string, id: number | null): void;
}>();

// Computed property to manage selectedItem based on selectedId
const selectedItem = computed({
  get() {
    return props.selectedId !== undefined
      ? props.values.find((item: any) => item.id === props.selectedId)
      : undefined;
  },
  set(newValue) {
    const newId = newValue ? newValue.id : null;
    emits('update:selectedSystem', props.label, newId);
  },
});
</script>

<template>
  <Dropdown
    v-if="loading"
    placeholder="Loading..."
    loading
    :class="`w-full min-w-[164px] max-w-max flex items-center justify-between px-4 gap-2 border !border-gray-200 hover:!border-gray-200 rounded-none first-of-type:rounded-tl first-of-type:rounded-bl last-of-type:rounded-tr last-of-type:rounded-br`"
    :pt="{
      input: { class: 'px-0 py-2' },
      panel: { class: 'mt-[2px] rounded overflow-hidden' },
    }"
  ></Dropdown>
  <Dropdown
    v-else-if="values.length > 0"
    v-model="selectedItem"
    :options="values"
    optionLabel="serial_number"
    :class="`w-full min-w-[164px] max-w-max flex items-center justify-between pl-4 px-3 border !border-gray-200 hover:!border-gray-200 rounded-none first-of-type:rounded-tl first-of-type:rounded-bl last-of-type:rounded-tr last-of-type:rounded-br`"
    :pt="{
      input: { class: '!p-0' },
      trigger: { class: 'w-auto max-w-max h-auto' },
      panel: { class: 'mt-[2px] rounded overflow-hidden' },
      list: { class: '!flex !flex-col !p-[2px] !gap-[2px]' },
      item: {
        class:
          'flex !rounded !py-2 !px-3 !text-base text-gray-600 bg-white hover:bg-gray-25 hover:text-gray-700',
      },
    }"
  >
    <template #value="{ value }">
      <div class="flex items-center">
        <div class="py-2">
          <span :class="`${value ? 'text-white' : 'text-gray-600'} text-base`">
            {{ label }}
          </span>
        </div>
        <div v-if="value" class="px-4 h-10 flex">
          <span class="h-full w-[1px] bg-gray-200"></span>
        </div>
        <div v-if="value" class="py-2">
          <span class="text-white text-base">{{ value.serial_number }}</span>
        </div>
      </div>
    </template>
    <template #dropdownicon>
      <div>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          class="input-icon flex w-[14px] h-[14px] ml-2"
        >
          <path
            d="M1 4L7 10L13 4"
            stroke="#555555"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </template>
  </Dropdown>
</template>
