<template>
  <Dialog
    :header="t('chargePark.deleteTitle')"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="flex field-no-edit">
      <div class="w-full info-single">
        <div class="flex justify-between py-2 info-item items-center">
          <span class="text-grey-medium font-semibold">
            {{ t('chargePark.chargeParkDetails.name') }}
          </span>
          <span class="text-grey-medium">{{ chargeParkDeleteData.name }}</span>
        </div>
        <div class="flex justify-between py-2 info-item items-center">
          <span class="text-grey-medium font-semibold">
            {{ t('chargePark.chargeParkDetails.owner_organisation_name') }}
          </span>
          <span class="text-grey-medium">{{ chargeParkDeleteData.owner_organisation_name }}</span>
        </div>
        <div class="flex justify-between py-2 info-item items-center">
          <span class="text-grey-medium font-semibold">
            {{ t('chargePark.chargeParkDetails.number_of_systems') }}
          </span>
          <span class="text-grey-medium">{{ chargeParkDeleteData.number_of_systems }}</span>
        </div>
      </div>
    </div>
    <div class="field flex flex-col field-edit mt-1">
      <Message severity="error" icon="pi pi-exclamation-triangle" :closable="false">
        {{ $t('chargePark.deleteWarning') }}
      </Message>
      <div class="font-medium text-sm leading-4 text-gray-600 grid gap-3 my-3">
        <p>
          {{ t('chargePark.deleteInfo.deleteWarning') }}
        </p>
        <ul class="grid gap-1 list-disc pl-4">
          <li>{{ t('chargePark.deleteInfo.brandings') }}</li>
          <li>{{ t('chargePark.deleteInfo.playlists') }}</li>
          <li>{{ t('chargePark.deleteInfo.tariffs') }}</li>
          <li>{{ t('chargePark.deleteInfo.alerts') }}</li>
          <li>{{ t('chargePark.deleteInfo.reports') }}</li>
        </ul>
        <p>
          {{ t('chargePark.deleteInfo.unassignedWarning') }}
        </p>
      </div>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        :label="t('delete')"
        class="p-button-sm p-button-footer p-button-error mx-auto mb-2 block mt-5"
        @click="deleteChargePark"
        :loading="loading"
      />
    </template>
  </Dialog>
  <Toast position="top-center" errorIcon="pi pi-info-circle" group="delete-chargePark" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
// PrimeVue Components
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Message from 'primevue/message';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
// Pinia Store
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
// Utils
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';

const { t } = useI18n();
const toast = useToast();
const chargeParkStore = useChargeParksStore();
const { chargeParkDeleteModalIsOpen, chargeParkDeleteData } = storeToRefs(chargeParkStore);

const isVisible = ref(false);
const loading = ref(false);

const closeModal = () => {
  chargeParkDeleteModalIsOpen.value = false;
  chargeParkStore.resetChargeParkDeleteData();
  loading.value = false;
};

const successSummary = computed(() => {
  return t('toast.success', {
    name: t('chargePark.title'),
    action: t('toast.deleted'),
  });
});

const errorSummary = computed(() => {
  return t('toast.error', {
    action: t('toast.deletion'),
    name: t('chargePark.title'),
  });
});

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: successSummary.value,
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'delete-chargePark',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: errorSummary.value,
    group: 'delete-chargePark',
  });
};

const deleteChargePark = async () => {
  loading.value = true;

  await chargeParkStore
    .deleteChargePark()
    .then((response) => {
      loading.value = false;
      if (response.status === 204) {
        toast.removeGroup('delete-chargePark');
        showSuccess();
        closeModal();
      }
    })
    .catch((error) => {
      loading.value = false;
      toast.removeGroup('delete-chargePark');
      showError();
      throw new Error(error);
    });
};

watch(
  () => chargeParkDeleteModalIsOpen.value,
  (value) => {
    if (value) {
      isVisible.value = true;
    } else {
      isVisible.value = false;
    }
  }
);
</script>
