import { UnitSystem } from '@/models/datatypes.model';

type UnitConversionMap = {
  [unit: string]: {
    prefixes: string[];
    system: (UnitSystem.METRIC | UnitSystem.IMPERIAL)[];
    alternateNames: string[];
    prefferedRepresentation?: string;
    to: {
      [unit: string]: string;
    };
  };
};

const prefferedConversions: [string, string][] = [
  ['mm', 'inch'],
  ['cm', 'inch'],
  ['m', 'feet'],
  ['km', 'miles'],
  ['ml', 'oz'],
  ['l', 'gal'],
  ['g', 'lbs'],
  ['Celcius', 'Fahrenheit'],
];

const conversionMap: UnitConversionMap = {
  W: {
    prefixes: ['m', 'k', 'M', 'dk'],
    system: [UnitSystem.METRIC, UnitSystem.IMPERIAL],
    alternateNames: ['Watt', 'Watts'],
    to: {},
  },
  Wh: {
    prefixes: ['m', 'k', 'M', 'dk'],
    system: [UnitSystem.METRIC, UnitSystem.IMPERIAL],
    alternateNames: ['WattHour'],
    to: {
      Cal: '$INPUT * 860.421',
    },
  },
  Cal: {
    prefixes: ['m', 'k', 'M'],
    system: [UnitSystem.METRIC, UnitSystem.IMPERIAL],
    alternateNames: ['Calorie', 'Calories'],
    to: {
      Wh: '$INPUT / 860.421',
    },
  },
  V: {
    prefixes: ['m', 'k', 'M', 'd'],
    system: [UnitSystem.METRIC, UnitSystem.IMPERIAL],
    alternateNames: ['Volt', 'Volts'],
    to: {},
  },
  A: {
    prefixes: ['m', 'k', 'M', 'd'],
    system: [UnitSystem.METRIC, UnitSystem.IMPERIAL],
    alternateNames: ['Ampere', 'Amperes', 'Amps', 'Amp'],
    to: {},
  },
  Celcius: {
    prefixes: ['c', 'd'],
    system: [UnitSystem.METRIC],
    alternateNames: ['Celsius', 'C', '°C', '° C', 'Deg. C', 'Deg C', 'DegC'],
    prefferedRepresentation: '°C',
    to: {
      Fahrenheit: '$INPUT * 9 / 5 + 32',
      K: '$INPUT + 273.15',
    },
  },
  Fahrenheit: {
    prefixes: [],
    system: [UnitSystem.IMPERIAL],
    alternateNames: ['F', '°F', '° F', 'Deg. F', 'Deg F', 'DegF'],
    prefferedRepresentation: '°F',
    to: {
      Celcius: '($INPUT - 32) * 5 / 9',
      K: '($INPUT - 32) * 5 / 9 + 273.15',
    },
  },
  Kelvin: {
    prefixes: ['m'],
    system: [UnitSystem.METRIC, UnitSystem.IMPERIAL],
    alternateNames: ['K', '°K', '°k'],
    to: {
      Celcius: '$INPUT - 273.15',
      Fahrenheit: '($INPUT - 273.15) * 9 / 5 + 32',
    },
  },
  m: {
    prefixes: ['m', 'c', 'k', 'd'],
    system: [UnitSystem.METRIC],
    alternateNames: ['meter', 'meters'],
    to: {
      feet: '$INPUT * 3.28084',
      inch: '$INPUT * 39.3701',
    },
  },
  miles: {
    prefixes: [],
    system: [UnitSystem.IMPERIAL],
    alternateNames: ['mile', 'mi'],
    to: {
      m: '$INPUT * 1609.34',
    },
  },
  feet: {
    prefixes: [],
    system: [UnitSystem.IMPERIAL],
    alternateNames: ['foot', 'ft', 'ft.'],
    to: {
      m: '$INPUT * 0.3048',
      inch: '$INPUT * 12',
    },
  },
  inch: {
    prefixes: [],
    system: [UnitSystem.IMPERIAL],
    alternateNames: ['in', 'in.', 'inches'],
    to: {
      m: '$INPUT * 0.0254',
      feet: '$INPUT / 12',
    },
  },
  g: {
    prefixes: ['m', 'k'],
    system: [UnitSystem.METRIC],
    alternateNames: ['gram', 'grams'],
    to: {
      lbs: '$INPUT * 0.00220462',
    },
  },
  lbs: {
    prefixes: [],
    system: [UnitSystem.IMPERIAL],
    alternateNames: ['pound', 'pounds', 'lb', 'lb.'],
    to: {
      g: '$INPUT * 453.592',
    },
  },
  l: {
    prefixes: ['m', 'c', 'd'],
    system: [UnitSystem.METRIC],
    alternateNames: ['liter', 'liters', 'litre', 'litres'],
    to: {
      gal: '$INPUT * 0.264172',
    },
  },
  lperMin: {
    prefixes: ['m', 'c', 'd'],
    system: [UnitSystem.METRIC],
    alternateNames: ['l/min'],
    to: { 
    },
  },
  gal: {
    prefixes: [],
    system: [UnitSystem.IMPERIAL],
    alternateNames: ['gallon', 'gallons', 'gal', 'gal.'],
    to: {
      l: '$INPUT * 3.78541',
    },
  },
  min: {
    prefixes: [],
    system: [UnitSystem.METRIC, UnitSystem.IMPERIAL],
    alternateNames: ['minute', 'minutes'],
    prefferedRepresentation: 'min',
    to: {
      sec: '$INPUT * 60',
      hour: '$INPUT / 60',
    },
  },
  sec: {
    prefixes: [],
    system: [UnitSystem.METRIC, UnitSystem.IMPERIAL],
    alternateNames: ['s', 'second', 'seconds'],
    prefferedRepresentation: 's',
    to: {
      min: '$INPUT / 60',
      hour: '$INPUT / 3600',
    },
  },
  hour: {
    prefixes: [],
    system: [UnitSystem.IMPERIAL],
    alternateNames: ['h', 'hr', 'hrs', 'hour', 'hours'],
    prefferedRepresentation: 'h',
    to: {
      min: '$INPUT * 60',
      sec: '$INPUT * 3600',
    },
  },
};

function normalizeValue(value: number, prefix: string): number {
  switch (prefix) {
    case 'm':
      return value / 1000;
    case 'c':
      return value / 100;
    case 'd':
      return value / 10;
    case 'k':
      return value * 1000;
    case 'M':
      return value * 1000000;
    case 'dk':
      //              (d)  * (k)
      return value * (1/10) * 1000 ;
    default:
      return value;
  }
}

function denormalizeValue(value: number, prefix: string): number {
  switch (prefix) {
    case 'm':
      return value * 1000;
    case 'c':
      return value * 100;
    case 'd':
      return value * 10;
    case 'k':
      return value / 1000;
    case 'M':
      return value / 1000000;
    case 'dk':
      //                (d)  *  (k)
      return value / ( (1/10)* 1000) ;
    default:
      return value;
  }
}

function separatePrefix(unitWithPrefix: string): { prefix: string | null; baseUnit: string } {
  const unitWithPrefixLower = unitWithPrefix.toLowerCase();

  for (const [unit, info] of Object.entries(conversionMap)) {
    for (const option of [unit, ...info.alternateNames].map((s) => s.toLowerCase())) {
      if (unitWithPrefixLower.endsWith(option.toLowerCase())) {
        if (unitWithPrefixLower === option.toLowerCase()) return { prefix: null, baseUnit: unit };

        const prefix = unitWithPrefix.slice(0, unitWithPrefix.length - option.length);
        if (conversionMap[unit].prefixes.includes(prefix)) return { prefix, baseUnit: unit };
      }
    }
  }

  return { prefix: null, baseUnit: unitWithPrefix };
}

export function convertValue<T extends number | number[]>(
  value: T,
  from?: string | null,
  to?: string | null
): [T, string] {
  if (!from) return [value, ''];
  if (!to) return [value, from];

  const { prefix: fromPrefix, baseUnit: fromBaseUnit } = separatePrefix(from);
  const { prefix: toPrefix, baseUnit: toBaseUnit } = separatePrefix(to);

  const values = Array.isArray(value) ? value : [value];

  // Normalize values
  for (let i = 0; i < values.length; i++) {
    values[i] = fromPrefix != null ? normalizeValue(values[i], fromPrefix) : values[i];
  }

  // Convert units
  if (fromBaseUnit !== toBaseUnit) {
    const conversionFunction = conversionMap[fromBaseUnit]?.to[toBaseUnit];
    if (!conversionFunction) return [value, from];

    for (let i = 0; i < values.length; i++) {
      const expression = conversionFunction.replace('$INPUT', values[i].toString());
      values[i] = Number(eval(expression));
    }
  }

  // Denormalize values
  if (toPrefix != null) {
    for (let i = 0; i < values.length; i++) {
      values[i] = denormalizeValue(values[i], toPrefix);
    }
  }

  return [Array.isArray(value) ? values : values[0], to];
}

export function applyDesiredRepresentation(unit: string): string {
  const { baseUnit, prefix } = separatePrefix(unit);
  const unitDefinition = conversionMap[baseUnit];
  return unitDefinition?.prefferedRepresentation
    ? (prefix ?? '') + unitDefinition.prefferedRepresentation
    : unit;
}

export function getUnitForSystem(originalUnit: string, desiredSystem: UnitSystem): string {
  const { baseUnit } = separatePrefix(originalUnit);
  const originalUnitSystem = conversionMap[baseUnit]?.system;

  if (originalUnitSystem === undefined || originalUnitSystem.includes(desiredSystem))
    return applyDesiredRepresentation(originalUnit);

  let exactMatchUnit = undefined;
  let baseUnitMatch = undefined;

  for (const [unit1, unit2] of prefferedConversions) {
    if (unit1 === originalUnit) exactMatchUnit = unit2;
    if (unit1 === baseUnit) baseUnitMatch = unit2;
    if (unit2 === originalUnit) exactMatchUnit = unit1;
    if (unit2 === baseUnit) baseUnitMatch = unit1;
  }

  if (exactMatchUnit !== undefined) return applyDesiredRepresentation(exactMatchUnit);
  if (baseUnitMatch !== undefined) return applyDesiredRepresentation(baseUnitMatch);

  return originalUnit;
}

export function convertSetting<T extends { unit?: string; minValue?: number; maxValue?: number }>(
  originalSetting: T,
  desiredUnitSystem: UnitSystem
): T {
  const desiredUnit =
    originalSetting.unit !== undefined
      ? getUnitForSystem(originalSetting.unit, desiredUnitSystem)
      : originalSetting.unit;

  const [convertedMinValue, convertedMinUnit] =
    originalSetting.minValue !== undefined
      ? convertValue(originalSetting.minValue, originalSetting.unit, desiredUnit)
      : [originalSetting.minValue, originalSetting.unit];

  const [convertedMaxValue, convertedMaxUnit] =
    originalSetting.maxValue !== undefined
      ? convertValue(originalSetting.maxValue, originalSetting.unit, desiredUnit)
      : [originalSetting.maxValue, originalSetting.unit];

  if (desiredUnit !== convertedMinUnit || desiredUnit !== convertedMaxUnit) return originalSetting;

  return {
    ...originalSetting,
    unit: desiredUnit,
    minValue: convertedMinValue,
    maxValue: convertedMaxValue,
  };
}
