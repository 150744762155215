<template>
  <page-header :overrideBackButtonFunction="backBtnPressed" show-back-button />
  <div v-if="chargingSessionExists !== undefined">
    <div
      v-if="chargingSessionExists"
      class="container container-grey grid grid-cols-1 min-[1300px]:grid-cols-3 gap-2"
    >
      <Panel :header="t('chargingSessions.chargingSession')" class="min-[1300px]:col-span-2">
        <div class="flex flex-col w-full md:divide-solid md:divide-y">
          <div class="grid md:grid-cols-2 gap-2 md:gap-6">
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.transaction_id')"
              :value="singleChargingSession.transaction_id"
            />
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.sessionID')"
              :value="singleChargingSession.session_id"
            />
          </div>

          <div class="grid md:grid-cols-2 gap-2 md:gap-6">
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.system_name')"
              :value="singleChargingSession.system_name"
            />
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.asset_serial')"
              :value="sessionsChargerSerial"
            />
          </div>

          <ChargingSessionDetailItem
            class="md:col-span-2"
            :title="$t('hardwareSystem.hardwareSystemDetails.custom_id')"
            :value="sessionsChargerInventoryNr"
          />

          <div class="grid md:grid-cols-2 md:gap-6">
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.connector_id')"
              :value="singleChargingSession.connector_id"
            />
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.evse_id')"
              :value="singleChargingSession.evse_id"
            />
          </div>

          <div class="grid md:grid-cols-2 md:gap-6">
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.session_start')"
              :value="singleChargingSession.session_start"
              :tooltip="$t('chargingSessions.tooltip.session_start')"
              isDateTime
            />
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.session_end')"
              :value="singleChargingSession.session_end"
              :tooltip="$t('chargingSessions.tooltip.session_end')"
              isDateTime
            />
          </div>
          <div class="grid md:grid-cols-2 md:gap-6">
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.duration')"
              :value="relativeDuration"
            />
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.mac_car')"
              :value="singleChargingSession.mac_car"
              breakAll
            />
          </div>

          <div class="grid md:grid-cols-2 md:gap-6">
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.payment_method')"
              :value="singleChargingSession.payment_method"
            />
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.payment_other')"
              :value="singleChargingSession.payment_other"
            />
          </div>
        </div>
      </Panel>
      <ConnectorStatusPanel
        :chargePointId="singleChargingSession.connector_id - 1"
        :systemId="singleChargingSession.system_id"
        :startTime="singleChargingSession.session_start"
        :endTime="singleChargingSession.session_end"
        :data="data ? data[0] : undefined"
        :enumValues="enumValues"
        :loading="loading"
        :emptyData="emptyData"
        :error="error"
      />
      <Panel :header="t('chargingSessions.powerAndEnergy')" class="min-[1300px]:col-span-2">
        <div class="flex flex-col w-full md:divide-solid md:divide-y">
          <div class="grid md:grid-cols-2 md:gap-6">
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.start_soc_percent')"
              :value="
                singleChargingSession.start_soc_percent !== null
                  ? singleChargingSession.start_soc_percent.toFixed(2)
                  : null
              "
              unit-suffix="%"
            />
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.end_soc_percent')"
              :value="
                singleChargingSession.end_soc_percent !== null
                  ? singleChargingSession.end_soc_percent.toFixed(2)
                  : null
              "
              unit-suffix="%"
            />
          </div>

          <div class="grid md:grid-cols-2 md:gap-6">
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.energy_kwh')"
              :value="singleChargingSession.energy_kwh.toFixed(4)"
              unit-suffix="kWh"
            />
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.peak_power_kw')"
              :value="
                singleChargingSession.peak_power_kw !== null
                  ? singleChargingSession.peak_power_kw.toFixed(0)
                  : null
              "
              unit-suffix="kW"
            />
          </div>
          <div class="grid md:grid-cols-2 md:gap-6">
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.average_power')"
              :value="
                singleChargingSession.average_power !== null
                  ? parseFloat(singleChargingSession.average_power).toFixed(0)
                  : null
              "
              unit-suffix="kW"
            />
            <ChargingSessionDetailItem
              :title="$t('chargingSessions.details.power_lower_than_requested_percent')"
              :value="
                singleChargingSession.power_lower_than_requested_percent !== null
                  ? singleChargingSession.power_lower_than_requested_percent.toFixed(2)
                  : null
              "
              :tooltip="$t('chargingSessions.tooltip.power_lower_than_requested_percent')"
              unit-suffix="%"
            />
          </div>
          <ChargingSessionDetailItem
            class="md:col-span-2"
            :title="$t('chargingSessions.details.evsp')"
            :value="singleChargingSession.evsp"
            breakAll
          />

          <ChargingSessionDetailItem
            class="md:col-span-2"
            :title="$t('chargingSessions.details.status')"
            :value="chargingSessionStore.getStatusName"
          />

          <ChargingSessionDetailItem
            class="md:col-span-2"
            :title="$t('chargingSessions.details.reason_session_end')"
            :value="singleChargingSession.reason_session_end"
          />
        </div>
      </Panel>
      <MultiTelemetryWidget
        v-if="singleChargingSession.system_id"
        class="col-span-1 min-[1300px]:col-span-full"
        :chargePointId="singleChargingSession.connector_id - 1"
        :systemId="singleChargingSession.system_id"
        :startTime="singleChargingSession.session_start"
        :endTime="singleChargingSession.session_end"
      />
    </div>
    <ErrorBar
      v-else
      title="404: Charging Session not found"
      message="The charging session you are looking for does not exist."
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
// PrimeVue
import Panel from 'primevue/panel';
// i18n
import { useI18n } from 'vue-i18n';
// Pinia stores
import { useChargingSessionsStore } from '@/stores/admin/chargingSessions/chargingSessions.store';
import { useProductTypesStore } from '@/stores/admin/productTypes/productTypes.store';
// API and Types
import type { DataResponse } from '@/stores/admin/controlPanel/controlPanel.types';
import type { ProductType, ProductTypeChannel } from '@/models/productTypes.model';
import type { ChargingSessionDetails } from '@/models/chargingSessions.model';
import { fetchHistoricalTelemetryData } from '@/stores/admin/controlPanel/controlPanel.api';
import { fetchHardwareSystem } from '@/stores/admin/hardwareSystems/hardwareSystems.api';
// Custom components
import ChargingSessionDetailItem from './ChargingSessionDetailItem.vue';
import PageHeader from '@/components/common/layout/PageHeader.vue';
import MultiTelemetryWidget from '../hardwareSystems/controlPanel/widgets/telemetry/MultiTelemetryWidget.vue';
import ConnectorStatusPanel from '../common/panel/ConnectorStatusPanel.vue';
import ErrorBar from '@/components/common/bar/ErrorBar.vue';
// Utility
import { getDurationFromSec } from '@/utils/dateTimeFormat';
import { isEnumTelemetryChannel } from '@/utils/telemetry';

const props = defineProps<{
  sessionID: number;
}>();

const { t } = useI18n();
const emit = defineEmits(['closeDetailsPage']);

// Instances of Pinia stores
const chargingSessionStore = useChargingSessionsStore();
const productTypesStore = useProductTypesStore();

// Get reactive refs from Pinia stores
const { singleChargingSession } = storeToRefs(chargingSessionStore);

// Refs
const data = ref<DataResponse | undefined>(undefined);
const productType = ref<ProductType | undefined>(undefined);
const telemetryDefinition = ref<ProductTypeChannel | undefined>(undefined);
const enumValues = ref<{ value: number; label: string }[] | undefined>(undefined);
const sessionsChargerInventoryNr = ref<string>('');
const sessionsChargerSerial = ref<string>('');
const chargingSessionExists = ref<boolean>();

// Loading, empty data, and error flags
const loading = ref<boolean>(true);
const emptyData = ref<boolean>(false);
const error = ref<string | undefined>(undefined);

// Computed property to format session duration
const relativeDuration = computed(
  () =>
    getDurationFromSec(singleChargingSession.value.duration) +
    ` (${singleChargingSession.value.duration} ${t('seconds')})`
);

const backBtnPressed = () => {
  emit('closeDetailsPage');
};

const startTimeComputed = computed(() => singleChargingSession.value.session_start);
const endTimeComputed = computed(() => singleChargingSession.value.session_end);

// Computed properties for start and end time in unix epoch seconds (for the connector status timeline)
const rangeStart = computed(() =>
  startTimeComputed.value
    ? new Date(startTimeComputed.value).valueOf() / 1000
    : Date.now() / 1000 - 1000 * 60 * 60
);
const rangeEnd = computed(() =>
  endTimeComputed.value ? new Date(endTimeComputed.value).valueOf() / 1000 : Date.now() / 1000
);

// Helper function to check if the fetched telemetry data actually contains data points (used for the connector status timeline)
function hasData(res: DataResponse) {
  return res.length > 0 && res[0].x.data.length > 0 && res[0].y.data.length > 0;
}

// Function to fetch telemetry data (used for the connector status timeline)
const fetchData = async () => {
  loading.value = true;

  await fetchHistoricalTelemetryData({
    paths: [
      {
        systemId: singleChargingSession.value.system_id,
        path: `functions.chargePoints.chargePoint${
          singleChargingSession.value.connector_id - 1
        }:22`,
      },
    ],
    start: rangeStart.value - 2,
    end: rangeEnd.value + 2,
    aggregation: { type: 'maxPoints', method: 'subSample', value: 200 },
  })
    .then((response) => {
      if (!hasData(response)) emptyData.value = true;
      data.value = response;
    })
    .catch((err: any) => {
      console.error(err);
      error.value = 'Error fetching telemetry data';
    })
    .finally(() => {
      loading.value = false;
    });
};

// Fetch connector status data
const fetchConnectorStatusData = async () => {
  // Fetch product type data
  productType.value = await productTypesStore.fetchProductTypesList().then((response) => {
    for (let i = 0; i < response.data.length; i++) {
      if (response.data[i].name === 'DirectPower') {
        return response.data[i];
      }
    }
  });

  if (productType.value) {
    //getting the telemetryData by channel
    Object.keys(productType.value.properties.channels).map((key) => {
      if (productType.value?.properties.channels[key].name === 'UI connector state') {
        telemetryDefinition.value = productType.value.properties.channels[key];
      }
    });
  }

  //storing the enumValues from the telemetryDefinition into a variable
  enumValues.value =
    telemetryDefinition.value && isEnumTelemetryChannel(telemetryDefinition.value)
      ? telemetryDefinition.value.enumValues
      : undefined;
};

const fetchChargingSessionData = async () => {
  // Fetch charging session details
  await chargingSessionStore
    .fetchChargingSession(props.sessionID)
    .then(async () => {
      if (props.sessionID === singleChargingSession.value.id) {
        chargingSessionExists.value = true;
      }
      if (singleChargingSession.value.system_id > 0) {
        await fetchHardwareSystem(singleChargingSession.value.system_id).then((response: any) => {
          sessionsChargerInventoryNr.value = response.data.custom_id;
          sessionsChargerSerial.value = response.data.nidec_id;
          return response;
        });
      }
    })
    .catch((err: any) => {
      chargingSessionExists.value = false;
      console.error(err);
    });

  // Fetch charging session status
  await chargingSessionStore.fetchChargingSessionStatus();
};

onMounted(async () => {
  await fetchChargingSessionData();

  await fetchConnectorStatusData();

  // Fetch telemetry data
  await fetchData();
});

onUnmounted(() => {
  // Clear reactive refs when component unmounts
  sessionsChargerInventoryNr.value = '';
  sessionsChargerSerial.value = '';
});
</script>

<style scoped lang="scss">
.p-panel {
  box-shadow: none;
  border: 1px solid $grey-medium-light;

  &.no-buttons {
    :deep(.p-panel-header) {
      padding: 18px 16px;
    }
  }
}

:deep(.p-panel-header) {
  padding: 16px 16px;
  background-color: $grey-light;
  border-bottom: 1px solid $grey-medium-light;
}

:deep(.p-panel-content) {
  padding: 4px 16px;
}

:deep(.p-panel-title) {
  font-size: 16px;
  color: $text-dark;
}
</style>
