<template>
  <Dialog
    :header="t('organisation.deleteTitle')"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="flex field-no-edit">
      <div class="w-full info-single">
        <div class="flex justify-between py-2 info-item items-center">
          <span class="text-grey-medium font-semibold">{{ t('organisation.name') }}</span>
          <span class="text-grey-medium">{{ organisationDeleteData.name }}</span>
        </div>
        <div class="flex justify-between py-2 info-item items-center">
          <span class="text-grey-medium font-semibold">
            {{ t('organisation.parent') }}
          </span>
          <span v-if="organisationDeleteData.parent_organisation_name" class="text-grey-medium">
            {{ organisationDeleteData.parent_organisation_name }}
          </span>
          <span v-else class="text-red-primary font-medium">
            {{ t('organisation.master') }}
          </span>
        </div>
        <div class="flex justify-between py-2 info-item items-center">
          <span class="text-grey-medium font-semibold">{{ t('organisation.users') }}</span>
          <span class="text-grey-medium">{{ organisationDeleteData.number_of_users }}</span>
        </div>
      </div>
    </div>
    <div class="field flex flex-col field-edit mt-1">
      <Message severity="error" icon="pi pi-exclamation-triangle" :closable="false">
        {{ $t('organisation.deleteWarning') }}
      </Message>
      <div class="font-medium text-sm leading-4 text-gray-600 grid gap-3 my-3">
        <p>{{ $t('organisation.deleteInfo.deleteWarning1') }}</p>
        <p>{{ $t('organisation.deleteInfo.deleteWarning2') }}</p>
        <p>{{ $t('organisation.deleteInfo.deleteWarning3') }}</p>
        <p>{{ $t('organisation.deleteInfo.deleteWarning4') }}</p>
        <ul class="grid gap-1 list-disc pl-4">
          <li>{{ t('organisation.deleteInfo.data_exports') }}</li>
          <li>{{ t('organisation.deleteInfo.alerts') }}</li>
          <li>{{ t('organisation.deleteInfo.reports') }}</li>
          <li>{{ t('organisation.deleteInfo.contacts') }}</li>
          <li>{{ t('organisation.deleteInfo.sites') }}</li>
          <li>{{ t('organisation.deleteInfo.playlists') }}</li>
          <li>{{ t('organisation.deleteInfo.brandings') }}</li>
          <li>{{ t('organisation.deleteInfo.tariffs') }}</li>
          <li>{{ t('organisation.deleteInfo.whitelists') }}</li>
        </ul>
      </div>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        :label="t('delete')"
        class="p-button-sm p-button-footer p-button-error mx-auto mb-2 block mt-5"
        @click="deleteOrganisation"
        :loading="loading"
      />
    </template>
  </Dialog>
  <Toast position="top-center" errorIcon="pi pi-info-circle" group="delete-organisation" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
// PrimeVue Components
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Message from 'primevue/message';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
// Custom Components
import Country from '@/components/common/countries/Country.vue';
// Pinia Store
import { useOrganisationsStore } from '@/stores/admin/organizations/organisations.store';
// Utils
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';

const { t } = useI18n();
const toast = useToast();
const organisationsStore = useOrganisationsStore();
const { organisationDeleteModalIsOpen, organisationDeleteData } = storeToRefs(organisationsStore);

const isVisible = ref(false);
const loading = ref(false);

const closeModal = () => {
  organisationDeleteModalIsOpen.value = false;
  organisationsStore.resetOrganisationDeleteData();
  loading.value = false;
};

const successSummary = computed(() => {
  return t('toast.success', {
    name: t('organisation.title'),
    action: t('toast.deleted'),
  });
});

const errorSummary = computed(() => {
  return t('toast.error', {
    action: t('toast.deletion'),
    name: t('organisation.title'),
  });
});

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: successSummary.value,
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'delete-organisation',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: errorSummary.value,
    group: 'delete-organisation',
  });
};

const deleteOrganisation = async () => {
  loading.value = true;

  await organisationsStore
    .deleteOrganisation()
    .then((response) => {
      loading.value = false;
      if (response.status === 204) {
        toast.removeGroup('delete-organisation');
        showSuccess();
        closeModal();
      }
    })
    .catch((error) => {
      loading.value = false;
      toast.removeGroup('delete-organisation');
      showError();
      throw new Error(error);
    });
};

watch(
  () => organisationDeleteModalIsOpen.value,
  (value) => {
    if (value) {
      isVisible.value = true;
    } else {
      isVisible.value = false;
    }
  }
);
</script>
