<template>
  <DataTable
    data-cy="charge-parks-table"
    :value="data.chargeParks"
    class="child-table p-datatable-sm mb-5 mt-2"
    dataKey="id"
    v-model:selection="selectedChargePark"
    selectionMode="single"
    @row-click="onRowSelect"
  >
    <template #empty>
      <span :data-cy="`charge-parks-table-${data.name}-empty`" class="w-full block text-center">{{
        $t('chargePark.empty')
      }}</span>
    </template>
    <Column field="name" :header="$t('name')" style="min-width: 13rem">
      <template #body="{ data }">
        <span data-cy="charge-park-name" :id="data.id">{{ data.name }}</span>
      </template>
    </Column>
    <Column field="country" :header="$t('country.title')" style="min-width: 12rem">
      <template #body="{ data }">
        <div data-cy="charge-park-country" class="flex justify-start">
          <Country :country-code="data.country_code" />
        </div>
      </template>
    </Column>
    <Column field="address" :header="$t('address')" style="min-width: 12rem">
      <template #body="{ data }">
        <span v-if="data.address" data-cy="charge-park-address">{{ data.address }}</span>
        <span v-else class="empty">--</span>
      </template>
    </Column>
    <Column
      field="latitude"
      :header="$t('chargePark.latitude')"
      data-cy="charge-park-latitude"
      style="min-width: 13rem"
    >
      <template #body="{ data }">
        <span v-if="data.latitude" data-cy="charge-park-latitude">{{ data.latitude }}</span>
        <span v-else class="empty">--</span>
      </template>
    </Column>
    <Column field="longitude" :header="$t('chargePark.longitude')" style="min-width: 13rem">
      <template #body="{ data }">
        <span v-if="data.longitude" data-cy="charge-park-longitude">{{ data.longitude }}</span>
        <span v-else class="empty">--</span>
      </template>
    </Column>
    <Column
      field="number_of_systems"
      :header="$t('hardwareSystem.systems')"
      style="min-width: 7rem"
    >
      <template #body="{ data }">
        <span data-cy="charge-park-assets"
          >{{ data.number_of_systems }} {{ $t('hardwareSystem.systems') }}</span
        >
      </template>
    </Column>
    <Column field="actions" :header="$t('actions')" class="w-56">
      <template #body="{ data }">
        <Button
          data-cy="open-charge-park-detail-view"
          :label="$t('details')"
          icon="pi pi-arrow-right"
          iconPos="right"
          class="flex p-button-sm p-button-outlined p-button-plain mr-2"
          @click="navigateToChargeParkDetailPage(data.id)"
        />
        <Button
          v-if="activeChargeParksRoles"
          :label="$t('delete')"
          icon="pi pi-trash"
          class="flex p-button-sm p-button-danger"
          @click="openDeleteChargeParkModal(data)"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
// Pinia Stores
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
// Types
import type { ChargePark } from '@/models/chargeParks.model';
import type { OrganisationWithChargeParks } from '@/models/organisation.model';
// Custom Components
import Country from '@/components/common/countries/Country.vue';
// PrimeVue Components
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
// Utils
import { CHARGE_PARKS } from '@/utils/routeNames';
import { Role } from '@/utils/constants';

const props = defineProps<{
  data: OrganisationWithChargeParks;
}>();

const router = useRouter();
const selectedChargePark = ref();

const chargeParkStore = useChargeParksStore();
const userStore = useUsersStore();
const { chargeParkDetailsId, chargeParkDeleteModalIsOpen, chargeParkDeleteData } =
  storeToRefs(chargeParkStore);

const navigateToChargeParkDetailPage = (chargeParkId: number) => {
  chargeParkDetailsId.value = chargeParkId;
  chargeParkStore.fetchChargePark(chargeParkId);
  router.push({ path: `/${CHARGE_PARKS}/${chargeParkId}` });
};

const onRowSelect = (event: { data: ChargePark }) => {
  navigateToChargeParkDetailPage(event.data.id);
};

const activeChargeParksRoles = computed(() => {
  return (
    userStore.me.roles.map((role) => role.id).includes(Role.ADMINISTRATOR) ||
    userStore.me.roles.map((role) => role.id).includes(Role.ASSET_MANAGER)
  );
});

const openDeleteChargeParkModal = (data: ChargePark) => {
  chargeParkDeleteData.value.id = data.id;
  chargeParkDeleteData.value.name = data.name;
  chargeParkDeleteData.value.owner_organisation_id = data.owner_organisation_id;
  chargeParkDeleteData.value.owner_organisation_name = data.owner_organisation_name;
  chargeParkDeleteData.value.number_of_systems = data.number_of_systems;
  chargeParkDeleteModalIsOpen.value = true;
};
</script>
