<template>
  <DropdownsNavbar
    v-if="activeTab === 'status'"
    :activeTab="activeTab"
    @update:selectedSystem="(id) => setSelectedChildSystem(id)"
  />
  <div class="scrollable">
    <div v-if="loading" class="loader-wrapper">
      <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
    </div>
    <ErrorBar v-else-if="error" title="Failed to load control panel" :message="error" />
    <control-panel-panels
      v-else-if="dashboard && system"
      :dashboard-id="system.default_dashboard_id"
      :system="system"
      :panels="dashboard.content.panels"
      activePanelId="status"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

import type { HardwareSystem } from '@/models/hardwareSystems.model';
import type { Dashboard } from '@/models/controlPanel/dashboard';

import ControlPanelPanels from '@/components/hardwareSystems/controlPanel/ControlPanelPanels.vue';
import ErrorBar from '@/components/common/bar/ErrorBar.vue';
import DropdownsNavbar from '../general/navigation/DropdownsNavbar.vue';

import { fetchDashboard } from '@/stores/admin/controlPanel/controlPanel.api';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';

const props = defineProps<{
  activeTab: string;
  system: HardwareSystem | null;
}>();

const systemStore = useHardwareSystemsStore();

const selectedChildSystem = ref<HardwareSystem | null>(props.system);

const setSelectedChildSystem = async (id: HardwareSystem['id'] | null) => {
  if (id !== null) {
    selectedChildSystem.value = await systemStore
      .fetchSingleSystem(id)
      .then((response) => response.data);
  }
};

const dashboard = ref<Dashboard | null>(null);
const loading = ref<boolean>(true);
const error = ref<string | null>(null);

async function getDashboard() {
  if (!props.system) return;

  loading.value = true;
  error.value = null;

  await fetchDashboard(props.system.id)
    .then((response) => (dashboard.value = response))
    .catch((e) => (error.value = e.message))
    .finally(() => (loading.value = false));
}

async function getChildSystemDashboard() {
  if (!selectedChildSystem.value) return;

  loading.value = true;
  error.value = null;

  await fetchDashboard(selectedChildSystem.value.id)
    .then((response) => (dashboard.value = response))
    .catch((e) => (error.value = e.message))
    .finally(() => (loading.value = false));
}

onMounted(() => {
  getDashboard();
});

watch(selectedChildSystem, () => getChildSystemDashboard());
</script>

<style scoped lang="scss">
.scrollable {
  height: 100%;
  overflow: auto;
}
</style>
