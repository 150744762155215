<template>
  <div class="control-container">
    <div class="flex flex-row items-start">
      <TabMenu
        v-if="!props.activePanelId"
        :model="props.panels"
        class="rounded-[4px] bg-white p-2 border border-gray-200 max-w-max"
        :pt="{
          menu: { class: 'flex gap-2 items-center !border-none' },
          menuitem: {
            class:
              'flex align-items-center rounded-[4px] overflow-hidden border border-gray-200  cursor-pointer text-gray-600 hover:border-green-primary hover:bg-green-primary-ultra-light hover:text-green-primary transition-colors duration-200',
          },
          inkbar: { class: '!hidden' },
        }"
      >
        <template #item="{ item }">
          <div class="px-4 py-1" @click="setActivePanelId(item.id)">
            <span class="font-base">{{ item.label }}</span>
          </div>
        </template>
      </TabMenu>
      <TabMenu
        v-if="activePanel?.tabs && activePanel?.tabs.length > 1"
        :model="activePanel.tabs"
        v-model:active-index="activeTabIndex"
        class="rounded-[4px] bg-white p-2 border border-gray-200"
        :pt="{
          menu: { class: 'flex gap-2 items-center !border-none' },
          menuitem: {
            class:
              'flex align-items-center rounded-[4px] overflow-hidden border border-gray-200 cursor-pointer text-gray-600 hover:border-green-primary hover:bg-green-primary-ultra-light hover:text-green-primary transition-colors duration-200',
          },
          inkbar: { class: '!hidden' },
        }"
      >
        <template #item="{ item }">
          <div class="px-4 py-1">
            <span class="font-base">{{ item.label }}</span>
          </div>
        </template>
      </TabMenu>
      <div class="status-container" title="Data Status">
        <i class="pi pi-spin pi-spinner" v-if="status === 'loading'" />
        <check-circle-icon style="height: 1rem" v-else-if="status === 'success'" class="success" />
        <exclamation-triangle-icon
          style="height: 1rem"
          v-else-if="status === 'error'"
          class="error"
        />
      </div>
    </div>

    <div v-if="activePanel === null" class="no-data">
      <p>No panels defined</p>
    </div>

    <div
      v-else-if="activePanel.rows"
      v-for="(row, rowIndex) in activePanel.rows"
      :key="rowIndex"
      class="row"
    >
      <div
        v-for="(column, colIndex) in row.columns"
        :key="colIndex"
        class="column"
        :style="{ gridColumnEnd: `span ${column.width}` }"
      >
        <template v-for="card in column.cards" :key="card.id">
          <widget-card
            v-if="card.widgets && card.widgets?.length > 0"
            :data-cy="`card-${card.id}`"
            :system-id="props.system.id"
            :card="card"
            :load-status="status"
            :unit-system="me.units"
          />
        </template>
      </div>
    </div>

    <div>
      <div
        v-if="
          activePanel?.tabs &&
          activePanel.tabs[activeTabIndex] &&
          activePanel.tabs[activeTabIndex].rows
        "
        v-for="(row, rowIndex) in activePanel.tabs[activeTabIndex].rows"
        :key="rowIndex"
        class="row"
      >
        <div
          v-for="(column, colIndex) in row.columns"
          :key="colIndex"
          class="column"
          :style="{ gridColumnEnd: `span ${column.width}` }"
        >
          <template v-for="card in column.cards" :key="card.id">
            <widget-card
              v-if="card.widgets && card.widgets?.length > 0"
              :data-cy="`card-${card.id}`"
              :system-id="props.system.id"
              :card="card"
              :load-status="status"
              :unit-system="me.units"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import TabMenu from 'primevue/tabmenu';
import WidgetCard from '@/components/hardwareSystems/controlPanel/widgets/common/WidgetCard.vue';
import { createControlPanelContext, filterWidgets } from '@/logic/controlPanel/widgets';
import type { Panel } from '@/models/controlPanel/dashboard';
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { useControlPanelStore } from '@/stores/admin/controlPanel/controlPanel.store';
import { data, type Events } from '@/logic/controlPanel/eventbus';
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/vue/20/solid';

const props = defineProps<{
  dashboardId?: number;
  system: HardwareSystem;
  panels: Panel[];
  activePanelId?: string;
}>();
const activeTabIndex = ref(0);
const { me } = useUsersStore();
const controlPanelStore = useControlPanelStore();

const activePanelId = ref(props.activePanelId ?? props.panels[0]?.id ?? null);

const controlPanelContext = computed(() => createControlPanelContext(props.system));

const status = ref<Events['status']['state']>('loading');

const mapRows = (row: any) => ({
  columns: row.columns.map((column: any) => ({
    cards: column.cards.map((card: any) => ({
      id: card.id,
      title: card.title,
      parameters: card.parameters,
      widgets: filterWidgets(controlPanelContext.value, card.widgets || []),
    })),
    width: column.width,
  })),
});

const activePanel = computed<Panel | null>(() => {
  if (!activePanelId.value) return null;
  const panel = props.panels.find((panel) => panel.id === activePanelId.value);
  if (!panel) return null;

  const tabs = panel?.tabs?.map((tab) => ({
    id: tab.id,
    label: tab.label,
    rows: tab.rows.map(mapRows),
  }));

  return {
    id: panel.id,
    label: panel.label,
    params: panel.params,

    rows: panel.rows?.map(mapRows),
    tabs: tabs,
  };
});

function setActivePanelId(panelId: string) {
  activePanelId.value = panelId;
}

onMounted(() => {
  controlPanelStore.initialize(props.system.id);
  controlPanelStore.startPolling(props.dashboardId, activePanelId.value ?? undefined);
});

function onStatusEvent(newStatus: Events['status']) {
  status.value = newStatus.state;
}

onMounted(() => data.on('status', onStatusEvent));
onUnmounted(() => data.off('status', onStatusEvent));
</script>

<style lang="scss">
.control-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  padding: 1rem;
  gap: 1rem;

  background-image: radial-gradient(#c9c9c9, transparent 5%);
  background-size: 40px 40px;
  background-position: 0 0, 50px 50px;
}

.control-panel-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: top;
}

.control-panel-tabs {
  display: flex;
  justify-content: flex-start;
  margin-right: auto;

  .tab {
    padding: 0.5rem 1rem;
    border: 1px solid var(--gray-200);
    background-color: var(--gray-0);
    cursor: pointer;

    &:first-child {
      border-radius: var(--rounded-md) 0 0 var(--rounded-md);
    }

    &:last-child {
      border-radius: 0 var(--rounded-md) var(--rounded-md) 0;
    }

    &:hover:not(.active) {
      background-color: var(--gray-50);
    }

    &.active {
      color: var(--gray-0);
      background-color: var(--green-primary);
    }

    &:not(:last-child) {
      border-right: 1px solid var(--gray-200);
    }
  }
}

.p-tabmenu {
  .p-tabmenuitem {
    &.p-highlight {
      background-color: var(--green-primary);
      color: white;
      border-color: var(--green-primary);
    }
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1rem;
  min-width: 1100px;

  box-sizing: border-box;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2rem;
  width: 2.2rem;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1rem;
  color: var(--gray-400);
  border: 1px solid var(--gray-200);
  background-color: var(--gray-0);
  border-radius: var(--rounded-md);
  margin-left: auto;

  .success {
    color: var(--green-primary);
  }

  .error {
    color: var(--red-primary);
  }
}
</style>
