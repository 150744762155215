<template> 
    <div v-if="component" class="flex flex-col" > 
            <!--<div>{{ $t('hardwareSystem.hardwareSystemDetails.name') }}:</div>
            <div class="font-bold" data-cy="active-error-component-name">{{ component.name }}</div>
            <div>{{ $t('hardwareSystem.hardwareSystemDetails.nidec_id') }}:</div>-->
            <div class="" data-cy="active-error-component-nidec-id">{{ component.nidec_id }}</div>  
            <!--<div>{{ $t('hardwareSystem.hardwareSystemDetails.product_type_name') }}:</div>-->
            <div class="font-thin text-sm text-gray-400" data-cy="active-error-component-product-type-name">{{ productTypeName }}</div>  
            <!--<div>{{ $t('hardwareSystem.hardwareSystemDetails.custom_id') }}:</div>
            <div class="font-bold" data-cy="active-error-component-custom-id">{{ component.custom_id }}</div> -->
        </div>
  </template>
  
  <script lang="ts" setup>
  import type { HardwareSubSystem } from '@/models/hardwareSystems.model'; 
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { useProductTypesStore } from '@/stores/admin/productTypes/productTypes.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
 
const hardwareSystemsStore = useHardwareSystemsStore();
 
  const props = defineProps<{
    component?: HardwareSubSystem; 
  }>();
 


const {getSystemByID} = hardwareSystemsStore;
const productTypeName = ref<string>('')
onMounted(()=>{
  if(props.component) productTypeName.value = getSystemByID(props.component?.id)?.product_type_name || ''
})
  </script>
  