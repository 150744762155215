import { defineStore } from 'pinia';
import type {
  HardwareSystem,
  HardwareUpdate,
  EventType,
  HardwareSystemUpdate,
  HardwareSystemTransfer,
  ConnectorStateType,
} from '@/models/hardwareSystems.model';
import {
  fetchHardwareSystems,
  delegateHardwareSystem,
  assignChargePark,
  unassignChargePark,
  editHardwareSystem,
  fetchFilteredHardwareSystems,
  updateSystem,
  fetchActiveErrors,
  fetchEventHistory,
  fetchEventTypes,
  fetchSystemsByChargePark,
  fetchHardwareSystem,
  fetchConnectorStateTypes,
  fetchConnectorsStatesSeries,
} from '@/stores/admin/hardwareSystems/hardwareSystems.api';
import type { Organisation } from '@/models/organisation.model';
import type { Log } from '@/models/logs.model';
import type {
  History,
  HistoryMeta,
  EventQueryParams,
  HistoryAggregation,
  ActiveError,
} from '@/models/history.model';
import type { Country } from '@/models/countries.model';
import { useCountriesStore } from '../countries/countries.store';
import { useOrganisationsStore } from '@/stores/admin/organizations/organisations.store';
import { DEFAULT_EVENT_HISTORY_PAGE_SIZE } from '@/utils/constants';
import type { ChargePark } from '@/models/chargeParks.model';

interface HardwareSystemsState {
  systems: HardwareSystem[];
  systemModalIsOpen: boolean;
  systemEditModalIsOpen: boolean;
  systemUpdateModalIsOpen: boolean;
  systemEditData: HardwareSystemUpdate;
  systemTransferData: HardwareSystemTransfer;
  actionType: string;
  filteredSystems: HardwareSystem[];
  logs: Log[];
  events: History[];
  eventsMeta: HistoryMeta;
  connectorStateTypes: ConnectorStateType[];
  activeErrors: ActiveError[];
  eventTypes: EventType[];
  singleSystem: HardwareSystem;
  systemsByChargePark: HardwareSystem[];
  eventDetailsModalIsOpen: boolean;
  eventDetails: History | HistoryAggregation | null;
  isFetchingData: boolean;
  controller: AbortController;
  connectorStateSeries: [string[], number[]];
}

export const useHardwareSystemsStore = defineStore('systems', {
  state: (): HardwareSystemsState => ({
    systems: [] as HardwareSystem[],
    systemModalIsOpen: false,
    systemEditModalIsOpen: false,
    systemUpdateModalIsOpen: false,
    systemEditData: {
      id: 0,
      nidec_id: '',
      name: '',
      custom_id: '',
      owner_organisation_id: 0,
      owner_organisation_name: '',
      charge_park_id: 0,
      charge_park_name: '',
    },
    systemTransferData: {
      id: 0,
      nidec_id: '',
      name: '',
      custom_id: '',
      owner_organisation_id: 0,
      owner_organisation_name: '',
      charge_park_id: 0,
      charge_park_name: '',
    },
    actionType: '',
    filteredSystems: [],
    logs: [],
    activeErrors: [],
    events: [],
    eventsMeta: {
      count: 0,
      next: null,
      previous: null,
    },
    eventTypes: [],
    connectorStateTypes: [],
    connectorStateSeries: [[], []],
    singleSystem: {
      id: 0,
      nidec_id: '',
      product_type_id: 0,
      product_type_name: '',
      default_dashboard_id: 0,
      name: '',
      custom_id: '',
      deleted_at: '',
      owner_organisation_id: 0,
      owner_organisation_name: '',
      charge_park_id: 0,
      charge_park_name: '',
      version: '',
      telco_tariff: null,
      charge_park_address: '',
      charge_park_country_code: '',
      latitude: '',
      longitude: '',
      address: '',
      service_started_at: null,
      service_ended_at: null,
      country_code: '',
      manufacturer: '',
      model: '',
      connector_state: [
        {
          id: 0,
          number_of_connectors: 0,
          states: {},
        },
      ],
      last_online_at: '',
      is_bys_connection_online: false,
      combined_system_setup: {
        parent: null,
        children: [],
        setup_error: null,
        setup_valid: false,
      },
    },
    systemsByChargePark: [],
    eventDetailsModalIsOpen: false,
    eventDetails: null,
    isFetchingData: false,
    controller: new AbortController(),
  }),
  getters: {
    hardwareSystemOrganisation(): Organisation | undefined {
      const organisationsStore = useOrganisationsStore();
      return organisationsStore.organisations.find(
        (org: Organisation) => org.id === this.systemEditData.owner_organisation_id
      );
    },
    hardwareSystemCountry(): Country | undefined {
      const countriesStore = useCountriesStore();
      return countriesStore.countries.find(
        (country: Country) => country.country_code === this.singleSystem.country_code
      );
    },
  },
  actions: {
    
    getSystemByID(id: number): HardwareSystem | undefined {
      return this.systems.find((item: HardwareSystem) => item.id === id);
    },
    async fetchSystemsList() {
      return await fetchHardwareSystems().then((response) => {
        this.systems = response.data;
        return response;
      });
    },

    async fetchSingleSystem(id: number) {
      return await fetchHardwareSystem(id).then((response) => {
        this.singleSystem = response.data;
        return response;
      });
    },

    async fetchSystemsByChargeParkId(id: number) {
      return await fetchSystemsByChargePark(id).then((response) => {
        this.systemsByChargePark = response;
        return response;
      });
    },

    async delegateSystemToOrganisation(systemId: number, organisationId: number) {
      return await delegateHardwareSystem(systemId, organisationId).then((response) => {
        this.fetchSystemsList();
        return response;
      });
    },

    async assignChargeParkToSystem(systemId: number, chargeParkId: number) {
      return await assignChargePark(systemId, chargeParkId).then((response) => {
        this.fetchSystemsList();
        return response;
      });
    },

    async unassignChargeParkFromSystem(systemId: number) {
      return await unassignChargePark(systemId).then((response) => {
        this.fetchSystemsList();
        return response;
      });
    },

    async updateHardwareSystem(systemId: number, data: HardwareSystemUpdate) {
      return await editHardwareSystem(systemId, data).then((response) => {
        this.fetchSystemsList();
        return response;
      });
    },

    async fetchSystemsForWhitelist(id: number) {
      return await fetchFilteredHardwareSystems(id).then((response) => {
        this.filteredSystems = response.data;
        return response;
      });
    },

    async updateSystemFirmware(systemId: number, data: HardwareUpdate) {
      return await updateSystem(systemId, data).then((response) => {
        this.logs = response.data;
        this.fetchEvents(systemId, { pageNumber: 1, pageSize: DEFAULT_EVENT_HISTORY_PAGE_SIZE });
        return response;
      });
    },

    async fetchActiveErrors(systemId: number) {
      this.isFetchingData = true;

      return await fetchActiveErrors(systemId)
        .then((response) => {
          this.activeErrors = response.data;
          this.isFetchingData = false;
          return response;
        })
        .finally(() => (this.isFetchingData = false));
    },

    async fetchEvents(systemId: number, params: EventQueryParams) {
      this.isFetchingData = true;

      return await fetchEventHistory(systemId, params)
        .then((response) => {
          const { count, previous, next, results } = response.data;
          this.events = results;
          this.eventsMeta = { count, previous, next };
          this.isFetchingData = false;
          return response;
        })
        .finally(() => (this.isFetchingData = false));
    },

    async fetchConnectorStateTypes() {
      return await fetchConnectorStateTypes().then((response) => {
        this.connectorStateTypes = response.data;
        return response;
      });
    },
    async fetchConnectorsStatesSeries(id?: ChargePark['id']) {
      return await fetchConnectorsStatesSeries(id).then((response) => {
        this.connectorStateSeries = response.data.series;
        return response;
      });
    },

    async fetchEventTypes() {
      return await fetchEventTypes().then((response) => {
        this.eventTypes = response.data;
        return response;
      });
    },

    async getProductTypeBySystemID(id: number) {
      const system = this.getSystemByID(id)
    },

    resetHardwareSystemEditData() {
      this.systemEditData = {
        id: 0,
        nidec_id: '',
        name: '',
        custom_id: '',
        owner_organisation_id: 0,
        owner_organisation_name: '',
        charge_park_id: 0,
        charge_park_name: '',
      };
    },
  },
});
